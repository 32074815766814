import { notification } from 'antd';
import { IconType } from 'antd/lib/notification';
import jwt_decode from 'jwt-decode';

interface TokenObject {
  role: string[];
}

export const openNotification = (type: IconType, title: string, description?: string, duration?: number): void => {
  notification[type]({
    message: title,
    description: description || '',
    duration: duration || 3
  });
};

export function getCookie(name: string): string | undefined {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');

  if (parts.length == 2) {
    return parts.pop()?.split(';').shift();
  }
}

export function eraseCookie(name: string): void {
  document.cookie = name + '=; max-Age=0';
}

export function setCookie(name: string, value: string, numberOfDays: number): void {
  const timeToAdd = 1000 * 60 * 60 * 24 * numberOfDays;
  const date = new Date();
  const expiryTime = date.getTime() + timeToAdd;
  date.setTime(expiryTime);
  const utcTime = date.toUTCString();
  document.cookie = `${name}=${value};expires='${utcTime}';`;
}

export function isAdminUser(token?: string): boolean {
  const registeredToken = token || getCookie('token');
  if (!registeredToken) return false;
  const decodedToken = jwt_decode(registeredToken) as TokenObject;
  return decodedToken.role.includes('Admin');
}
