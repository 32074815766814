import { configureStore, createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import {
  attendanceAddAsync,
  currentEventDetailsChangeAsync,
  eventsChangeAsync,
  eventsProposalsChangeAsync,
  eventAddAsync,
  eventEditAsync,
  loginAsync,
  eventDetailsChangeAsync,
  eventAddProposalAsync,
  validateEventAsync,
  deleteEventAsync
} from './actions';
import { DEFAULT_RACE_STATE } from './constants';
import { RaceState } from './interface';
import {
  onAttendanceAddAsyncFullFilled,
  onAttendanceAddAsyncPending,
  onAttendanceAddAsyncRejected,
  onCurrentEventDetailsChangeAsyncFullFilled,
  onCurrentEventDetailsChangeAsyncPending,
  onCurrentEventDetailsChangeAsyncRejected,
  onDeleteEventAsyncPending,
  onDeleteEventFullFilled,
  onDeleteEventRejected,
  OnDrawerEventVisibilityChage,
  onEventAddAsyncFullFilled,
  onEventAddAsyncPending,
  onEventAddAsyncRejected,
  onEventAddProposalAsyncPending,
  onEventAddProposalFullFilled,
  onEventEditAsyncFullFilled,
  onEventEditAsyncPending,
  onEventEditAsyncRejected,
  onEventsChange,
  onEventsChangeAsyncFullFilled,
  onEventsChangeAsyncPending,
  onEventsChangeAsyncRejected,
  onEventsProposalsChangeAsyncFullFilled,
  onEventsProposalsChangeAsyncPending,
  onEventsProposalsChangeAsyncRejected,
  onLoginAsyncFullFilled,
  onLoginAsyncPending,
  onLoginAsyncRejected,
  OnModalEditEventVisibilityChange,
  OnModalProposalEventVisibilityChange,
  OnResetCurrentEventDetails,
  OnResetSelectedEvent,
  OnSelectedEventChange,
  OnUserAdminStatusChange,
  OnUserIsConnectedStatusChange,
  onValidateEventAsyncPending,
  onValidateEventFullFilled,
  onValidateEventRejected
} from './reducer';

const raceSlice = createSlice({
  name: 'race',
  initialState: DEFAULT_RACE_STATE,
  reducers: {
    eventsChange: onEventsChange,
    selectedEventChange: OnSelectedEventChange,
    resetSelectedEventChange: OnResetSelectedEvent,
    resetCurrentEventDetails: OnResetCurrentEventDetails,
    drawerEventVisibilityChange: OnDrawerEventVisibilityChage,
    modalEditEventVisibilityChange: OnModalEditEventVisibilityChange,
    modalProposalEventVisibilityChange: OnModalProposalEventVisibilityChange,
    userAdminStatusChange: OnUserAdminStatusChange,
    userIsConnectedStatusChange: OnUserIsConnectedStatusChange
  },
  extraReducers: builder =>
    builder
      .addCase(eventsChangeAsync.pending, onEventsChangeAsyncPending)
      .addCase(eventsChangeAsync.fulfilled, onEventsChangeAsyncFullFilled)
      .addCase(eventsChangeAsync.rejected, onEventsChangeAsyncRejected)
      .addCase(eventsProposalsChangeAsync.pending, onEventsProposalsChangeAsyncPending)
      .addCase(eventsProposalsChangeAsync.fulfilled, onEventsProposalsChangeAsyncFullFilled)
      .addCase(eventsProposalsChangeAsync.rejected, onEventsProposalsChangeAsyncRejected)
      .addCase(currentEventDetailsChangeAsync.pending, onCurrentEventDetailsChangeAsyncPending)
      .addCase(currentEventDetailsChangeAsync.fulfilled, onCurrentEventDetailsChangeAsyncFullFilled)
      .addCase(currentEventDetailsChangeAsync.rejected, onCurrentEventDetailsChangeAsyncRejected)
      .addCase(attendanceAddAsync.pending, onAttendanceAddAsyncPending)
      .addCase(attendanceAddAsync.fulfilled, onAttendanceAddAsyncFullFilled)
      .addCase(attendanceAddAsync.rejected, onAttendanceAddAsyncRejected)
      .addCase(eventAddAsync.pending, onEventAddAsyncPending)
      .addCase(eventAddAsync.fulfilled, onEventAddAsyncFullFilled)
      .addCase(eventAddAsync.rejected, onEventAddAsyncRejected)
      .addCase(eventEditAsync.pending, onEventEditAsyncPending)
      .addCase(eventEditAsync.fulfilled, onEventEditAsyncFullFilled)
      .addCase(eventEditAsync.rejected, onEventEditAsyncRejected)
      .addCase(eventAddProposalAsync.pending, onEventAddProposalAsyncPending)
      .addCase(eventAddProposalAsync.fulfilled, onEventAddProposalFullFilled)
      .addCase(eventAddProposalAsync.rejected, onEventAddAsyncRejected)
      .addCase(loginAsync.pending, onLoginAsyncPending)
      .addCase(loginAsync.fulfilled, onLoginAsyncFullFilled)
      .addCase(loginAsync.rejected, onLoginAsyncRejected)
      .addCase(validateEventAsync.pending, onValidateEventAsyncPending)
      .addCase(validateEventAsync.fulfilled, onValidateEventFullFilled)
      .addCase(validateEventAsync.rejected, onValidateEventRejected)
      .addCase(deleteEventAsync.pending, onDeleteEventAsyncPending)
      .addCase(deleteEventAsync.fulfilled, onDeleteEventFullFilled)
      .addCase(deleteEventAsync.rejected, onDeleteEventRejected)
});

export const actions = {
  ...raceSlice.actions,
  eventsChangeAsync,
  eventsProposalsChangeAsync,
  currentEventDetailsChangeAsync,
  eventDetailsChangeAsync,
  attendanceAddAsync,
  eventAddAsync,
  eventEditAsync,
  eventAddProposalAsync,
  loginAsync,
  validateEventAsync,
  deleteEventAsync
};

const { reducer } = raceSlice;

export const store = configureStore({ reducer });

export type RaceDispatch = typeof store.dispatch;

export interface RaceThunkApi {
  dispatch: RaceDispatch;
  state: RaceState;
}

export function useRaceDispatch(): RaceDispatch {
  return useDispatch<RaceDispatch>();
}
