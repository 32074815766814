import { Spin } from 'antd';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isAdminUser } from '../../common/utils';
import EventDrawer from '../../components/EventDrawer/EventDrawer';
import EventModal from '../../components/EventModal/EventModal';
import EventsList from '../../components/EventsList/EventsList';
import RaceHeader from './RaceHeader/RaceHeader';
import './Race.css';
import { EventDetails, EventsTreeView, EventToCreate, isEventDetails } from '../../state/interface';
import {
  selectCurrentEventDetails,
  selectEventDrawerVisible,
  selectEventEditModalVisible,
  selectEventProposalModalVisible,
  selectEventsLoading,
  selectSelectedEventId,
  selectTreeViewEvents
} from '../../state/selector';
import { actions, useRaceDispatch } from '../../state/store';
import ProposalEventModal from '../EventModal/ProposalEventModal';
import { useSearchEventsProposals } from '../../common/hooks';

interface RaceProps {
  eventsTreeView: EventsTreeView;
  loading: boolean;
  drawerEventIsVisible: boolean;
  eventEditModalIsVisible: boolean;
  eventProposalModalIsVisible: boolean;
  searchDateFrom: string;
  searchDateTo: string;
  eventDetails?: EventDetails;
  selectedEventId?: number;
  isAdmin: boolean;
}

const useRaceProps = (): RaceProps => {
  const eventsTreeView = useSelector(selectTreeViewEvents);
  const loading = useSelector(selectEventsLoading);
  const selectedEventId = useSelector(selectSelectedEventId);
  const drawerEventIsVisible = useSelector(selectEventDrawerVisible);
  const eventDetails = useSelector(selectCurrentEventDetails);
  const eventEditModalIsVisible = useSelector(selectEventEditModalVisible);
  const eventProposalModalIsVisible = useSelector(selectEventProposalModalVisible);
  const startOfMonth = moment().clone().startOf('month').format('MM/DD/YYYY');
  const endOfMonth = moment().clone().endOf('month').format('MM/DD/YYYY');
  return {
    eventsTreeView: useMemo(() => eventsTreeView, [eventsTreeView]),
    loading: useMemo(() => loading, [loading]),
    selectedEventId: useMemo(() => selectedEventId, [selectedEventId]),
    drawerEventIsVisible: useMemo(() => drawerEventIsVisible, [drawerEventIsVisible]),
    eventDetails: useMemo(() => eventDetails, [eventDetails]),
    eventEditModalIsVisible: useMemo(() => eventEditModalIsVisible, [eventEditModalIsVisible]),
    eventProposalModalIsVisible: useMemo(() => eventProposalModalIsVisible, [eventProposalModalIsVisible]),
    searchDateFrom: useMemo(() => startOfMonth, [startOfMonth]),
    searchDateTo: useMemo(() => endOfMonth, [endOfMonth]),
    isAdmin: isAdminUser()
  };
};

const Race: FC = () => {
  const dispatch = useRaceDispatch();
  const {
    eventsTreeView,
    loading,
    drawerEventIsVisible,
    eventDetails,
    eventEditModalIsVisible,
    eventProposalModalIsVisible,
    selectedEventId,
    searchDateFrom,
    searchDateTo,
    isAdmin
  } = useRaceProps();

  const handleSearchEvents = useCallback(
    (dateFrom: string, dateTo: string) => {
      dispatch(actions.eventsChangeAsync({ dateFrom, dateTo }));
    },
    [dispatch]
  );

  const reloadEvents = useCallback(() => {
    handleSearchEvents(
      moment(searchDateFrom, 'MM/DD/YYYY').format('DD/MM/YYYY'),
      moment(searchDateTo, 'MM/DD/YYYY').format('DD/MM/YYYY')
    );
  }, [searchDateFrom, searchDateTo]);

  const handleShowEventAttendancesDrawer = useCallback(
    (eventId: number) => {
      dispatch(actions.selectedEventChange(eventId));
      dispatch(actions.currentEventDetailsChangeAsync(eventId));
      dispatch(actions.drawerEventVisibilityChange(true));
    },
    [dispatch]
  );

  const handleShowEventDetails = useCallback(
    (eventId: number) => {
      dispatch(actions.selectedEventChange(eventId));
      dispatch(actions.currentEventDetailsChangeAsync(eventId));
      dispatch(actions.modalEditEventVisibilityChange(true));
    },
    [dispatch]
  );

  const handleValidEvent = useCallback(
    (eventId: number) => {
      dispatch(actions.validateEventAsync(eventId))
    },
    [dispatch]
  );


  const handleDeleteEvent = useCallback(
    (eventId: number) => {
      return dispatch(actions.deleteEventAsync(eventId)).then(() => {
        window.location.reload()
      })
    },
    [dispatch]
  );

  const handleCloseEventDrawer = useCallback(() => {
    dispatch(actions.drawerEventVisibilityChange(false));
    dispatch(actions.resetSelectedEventChange());
    dispatch(actions.resetCurrentEventDetails());
  }, [dispatch]);

  const handleAttendEvent = useCallback(
    (eventId: number, username: string) => {
      if (!eventId) return;
      dispatch(actions.attendanceAddAsync({ eventId, username }));
    },
    [dispatch]
  );

  const handleShowEventEditModal = useCallback(() => {
    dispatch(actions.modalEditEventVisibilityChange(true));
  }, []);

  const handleShowEventProposalModal = useCallback(() => {
    dispatch(actions.modalProposalEventVisibilityChange(true));
  }, []);

  const handleCloseEventEditModal = useCallback(() => {
    dispatch(actions.modalEditEventVisibilityChange(false));
    dispatch(actions.resetSelectedEventChange());
    dispatch(actions.resetCurrentEventDetails());
  }, [dispatch]);

  const handleCloseEventProposalModal = useCallback(() => {
    dispatch(actions.modalProposalEventVisibilityChange(false));
    dispatch(actions.resetSelectedEventChange());
    dispatch(actions.resetCurrentEventDetails());
  }, [dispatch]);

  const handleSaveEventEditModal = useCallback(
    (event: EventDetails | EventToCreate) => {
      if (isEventDetails(event)) {
        // TODO find type for dispatch action return
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dispatch(actions.eventEditAsync(event)).then((response: any) => {
          if (!response.error) reloadEvents();
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dispatch(actions.eventAddAsync(event)).then((response: any) => {
          if (!response.error) reloadEvents();
        });
      }
    },
    [dispatch, searchDateFrom, searchDateTo]
  );

  const handleSaveEventProposalModal = useCallback(
    (event: EventDetails | EventToCreate) => {
      dispatch(actions.eventAddProposalAsync(event));
    },
    [dispatch, searchDateFrom, searchDateTo]
  );
  const handleSearchEventsProposals = useSearchEventsProposals();

  useEffect(() => {
    if (selectedEventId) {
      dispatch(actions.currentEventDetailsChangeAsync(selectedEventId));
    }
  }, [dispatch, selectedEventId]);

  useEffect(() => {
    handleSearchEventsProposals();
    handleSearchEvents(
      moment(searchDateFrom, 'MM/DD/YYYY').format('DD/MM/YYYY'),
      moment(searchDateTo, 'MM/DD/YYYY').format('DD/MM/YYYY')
    );
  }, []);

  return (
    <>
      <div className="main-container">
        <RaceHeader
          isAdmin={isAdmin}
          defaultDateFrom={searchDateFrom}
          defaultDateTo={searchDateTo}
          onSearch={handleSearchEvents}
          onShowEventEditModal={handleShowEventEditModal}
          onShowEventProposalModal={handleShowEventProposalModal}
        />
        {loading ? (
          <div className="spin-container">
            <Spin tip="Chargement en cours..." />
          </div>
        ) : (
          <div className="content">
            <div className="events-list-container">
              <EventsList
                isAdmin={isAdmin}
                events={eventsTreeView}
                noDataMessage={'Pas de résultats sur cette période'}
                onShowEventAttendancesDrawer={handleShowEventAttendancesDrawer}
                onShowEventDetails={handleShowEventDetails}
                onValidateEvent={handleValidEvent}
                onDeleteEvent={handleDeleteEvent}
              />
            </div>
            <EventDrawer
              eventDetails={eventDetails}
              visible={drawerEventIsVisible}
              onAddAttend={handleAttendEvent}
              onClose={handleCloseEventDrawer}
            />
          </div>
        )}
        <EventModal
          event={eventDetails}
          visible={eventEditModalIsVisible}
          onClose={handleCloseEventEditModal}
          onSaveEvent={handleSaveEventEditModal}
        ></EventModal>
        <ProposalEventModal
          event={eventDetails}
          visible={eventProposalModalIsVisible}
          onClose={handleCloseEventProposalModal}
          onSaveEvent={handleSaveEventProposalModal}
        ></ProposalEventModal>
      </div>
    </>
  );
};

export default Race;
