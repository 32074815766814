import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import Race from './Race';
import { selectUser } from '../../state/selector';
import { actions, useRaceDispatch } from '../../state/store';
import { useUserConnexionInfo } from '../../common/hooks';

const RaceContainer: FC = () => {
  const dispatch = useRaceDispatch();
  const [passwordValue, setPasswordValue] = useState();
  const user = useSelector(selectUser);
  const onLogin = useCallback(() => passwordValue && dispatch(actions.loginAsync(passwordValue)), [passwordValue]);
  const passWordChange = useCallback(e => setPasswordValue(e.target.value), []);
  useUserConnexionInfo();

  return user?.isConnected ? (
    <Race />
  ) : (
    <Modal
      title="Connexion"
      visible={true}
      footer={[
        <Button key="submit" type="primary" onClick={onLogin}>
          Se connecter
        </Button>
      ]}
    >
      <Input.Password
        placeholder="Mot de passe"
        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        value={passwordValue}
        onChange={passWordChange}
      />
    </Modal>
  );
};

export default RaceContainer;
