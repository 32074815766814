import { Moment } from 'moment';

export type EventType = 'Triathlon' | 'Duathlon' | 'SwimRun' | 'Running' | 'Trail' | 'RunAndBike' | 'Other';

export interface Attendance {
  name: string;
}

export interface EditingEvent {
  id?: number;
  name?: string;
  location?: string;
  date?: Moment;
  hour?: Moment;
  distance?: string;
  type?: EventType;
  comment?: string;
  registrationLink?: string;
  resultsLink?: string;
  websiteLink?: string;
}

export type EventStatus = 'Pending' | 'Validated' | 'Canceled';

export interface EventDetails {
  id: number;
  name: string;
  location: string;
  date: string;
  distance: string;
  type: EventType;
  attendances?: Attendance[];
  status: EventStatus;
  attendeesCount?: number;
  comment?: string;
  registrationLink?: string;
  resultsLink?: string;
  websiteLink?: string;
}

export interface EventToCreate {
  name: string;
  location: string;
  date: string;
  distance: string;
  type: EventType;
  attendances?: Attendance[];
  attendeesCount?: number;
  comment?: string;
  registrationLink?: string;
  resultsLink?: string;
  websiteLink?: string;
}
export interface EventAttend {
  eventId: number;
  username: string;
}

export interface EventUser {
  idEvent: number;
  username: string;
}

export interface User {
  username: string;
}

export type EventTree = Record<number, EventDetails[]>;

export interface RaceStateUI {
  eventsTree: Record<number, EventTree>;
  eventsLoading: boolean;
  eventsProposalsLoading: boolean;
  selectedEventId?: number;
  eventDrawerVisible: boolean;
  eventEditModalVisible: boolean;
  eventProposalModalVisible: boolean;
  eventsByKey: Record<number, EventDetails>;
  currentEventDetails?: EventDetails;
}

export interface UserDetails {
  isConnected: boolean;
  admin?: boolean;
}

export interface RaceState {
  entities: {
    events: EventDetails[];
    eventsProposals: EventDetails[];
    user?: UserDetails;
  };
  ui: RaceStateUI;
  jwtToken?: string;
}

export type EventsByMonth = Record<string, EventDetails[]>;
export type EventsTreeView = Record<number, EventsByMonth>;

export function isEventDetails(event: EventDetails | EventToCreate): event is EventDetails {
  return (event as EventDetails).id !== undefined;
}

export interface AuthResponse {
  jwtToken: string;
}
