import { BellOutlined } from '@ant-design/icons';
import { Badge, Tooltip } from 'antd';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Container, Navbar, NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { useSelector } from 'react-redux';
import { selectEventsProposals, selectUser } from './Race/state/selector';

const NavMenu: FC = () => {
  const history = useHistory();
  const onShowAttendancesPage = useCallback(() => {
    history.push(`/event/proposals`);
  }, [history]);
  const user = useSelector(selectUser);
  const isAdmin = useMemo(() => user?.admin, [user]);
  const [hasEventsProposals, setHasEventsProposals] = useState<boolean>(false);
  const eventsProposals = useSelector(selectEventsProposals);
  useEffect(() => {
    setHasEventsProposals(eventsProposals.length > 0);
  }, [eventsProposals]);
  return (
    <header>
      <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
        <Container>
          <div>
            <NavbarBrand className="header-title" tag={Link} to="/">
              QUI COURT OÙ
            </NavbarBrand>
            <a
              className="header__logo"
              href="https://triathlon-club-nantais.com"
              rel="home"
              title="Triathlon Club Nantais - Accueil"
            >
              <img
                src="https://triathlon-club-nantais.com/wp-content/uploads/2018/10/logo-tcn-203x85.png"
                alt="Triathlon Club Nantais"
                width="203"
                height="85"
              />
            </a>
          </div>
          {isAdmin ? (
            <Tooltip title="Voir les demandes à valider" color={'#ff8000'}>
              <Badge dot={hasEventsProposals} className="notification-badge">
                <BellOutlined style={{ fontSize: 20 }} onClick={onShowAttendancesPage}></BellOutlined>
              </Badge>
            </Tooltip>
          ) : null}
        </Container>
      </Navbar>
    </header>
  );
};

export default NavMenu;
