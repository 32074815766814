import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  attendToEvent,
  createEvent,
  createProposalEvent,
  deleteEvent,
  editEvent,
  getEventDetails,
  getEvents,
  getEventsProposals,
  login,
  validateEvent
} from '../common/api';
import { EventDetails, EventToCreate } from './interface';

export const eventsChangeAsync = createAsyncThunk(
  'race/eventsChangeAsync',
  ({ dateFrom, dateTo }: { dateFrom: string; dateTo: string }) => getEvents(dateFrom, dateTo)
);

export const eventsProposalsChangeAsync = createAsyncThunk('race/eventsProposalsChangeAsync', () =>
  getEventsProposals()
);

export const currentEventDetailsChangeAsync = createAsyncThunk(
  'race/currentEventDetailsChangeAsync',
  (eventId: number) => getEventDetails(eventId)
);

export const eventDetailsChangeAsync = createAsyncThunk('race/eventDetailsChangeAsync', (eventId: number) =>
  getEventDetails(eventId)
);

export const attendanceAddAsync = createAsyncThunk(
  'race/attendanceAsync',
  ({ eventId, username }: { eventId: number; username: string }) => attendToEvent(eventId, username)
);

export const eventAddAsync = createAsyncThunk('race/eventAddAsync', (event: EventToCreate) => createEvent(event));

export const eventEditAsync = createAsyncThunk('race/eventEditAsync', (event: EventDetails) => editEvent(event));

export const eventAddProposalAsync = createAsyncThunk('race/eventAddProposalAsync', (event: EventToCreate) =>
  createProposalEvent(event)
);

export const loginAsync = createAsyncThunk('race/login', (password: string) => login(password));

export const validateEventAsync = createAsyncThunk('race/validateEventAsync', (eventId: number) =>
  validateEvent(eventId)
);

export const deleteEventAsync = createAsyncThunk('race/deleteEventAsync', (eventId: number) =>
  deleteEvent(eventId)
);
