import { CalendarOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { FC, useMemo } from 'react';
import { EventsTreeView } from '../../state/interface';
import EventsCollapse from '../EventsCollapse/EventsCollapse';
import './EventsList.css';

interface EventsListProps {
  isAdmin: boolean;
  events: EventsTreeView;
  noDataMessage: string;
  onShowEventAttendancesDrawer?: (eventId: number) => void;
  onShowEventDetails: (eventId: number) => void;
  onValidateEvent: (eventId: number) => void;
  onDeleteEvent: (eventId: number) => void;
}

const EventsList: FC<EventsListProps> = ({
  isAdmin,
  events,
  noDataMessage,
  onShowEventAttendancesDrawer,
  onShowEventDetails,
  onValidateEvent,
  onDeleteEvent
}) => {
  const years = useMemo(() => Object.keys(events) as unknown as number[], [events]);
  return years.length > 0 ? (
    <>
      {years.map(year => (
        <div key={year}>
          <Divider>{year}</Divider>
          {Object.keys(events[year]).map(month => (
            <div key={`${year}-${month}`} className="events-container">
              <div style={{ display: 'flex' }}>
                <Title className="title" level={2}>
                  <CalendarOutlined className="title-icon" />
                  <span className="title-label">{`${month} ${year}`}</span>
                </Title>
              </div>
              <EventsCollapse
                isAdmin={isAdmin}
                events={events[year][month]}
                onShowAttendancesDrawer={onShowEventAttendancesDrawer}
                onShowDetails={onShowEventDetails}
                onValidateEvent={onValidateEvent}
                onDeleteEvent={onDeleteEvent}
              />
            </div>
          ))}
        </div>
      ))}
    </>
  ) : (
    <div>{noDataMessage}</div>
  );
};

export default EventsList;
