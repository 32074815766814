import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchEventsProposals, useUserConnexionInfo } from '../../common/hooks';
import {
  CloseEventProposalModalHandler,
  SaveEventHandler,
  ShowEventDetailsHandler,
  ValidEventHandler
} from '../../common/interfaces';
import { EventDetails, EventsTreeView, EventToCreate, isEventDetails } from '../../state/interface';
import {
  selectCurrentEventDetails,
  selectEventProposalModalVisible,
  selectEventsProposals,
  toTreeView
} from '../../state/selector';
import { actions, useRaceDispatch } from '../../state/store';
import ProposalEventModal from '../EventModal/ProposalEventModal';
import EventsList from '../EventsList/EventsList';
import './EventsProposalPage.css';

function useHandleShowEventDetails(): ShowEventDetailsHandler {
  const dispatch = useRaceDispatch();
  return useCallback(
    (eventId: number) => {
      dispatch(actions.currentEventDetailsChangeAsync(eventId));
      dispatch(actions.modalProposalEventVisibilityChange(true));
    },
    [dispatch]
  );
}

function useHandleCloseEventProposalModal(): CloseEventProposalModalHandler {
  const dispatch = useRaceDispatch();
  return useCallback(() => {
    dispatch(actions.modalProposalEventVisibilityChange(false));
    dispatch(actions.resetCurrentEventDetails());
  }, [dispatch]);
}

function useHandleValidEvent(): ValidEventHandler {
  const dispatch = useRaceDispatch();
  return useCallback(
    async (eventId: number) => { await dispatch(actions.validateEventAsync(eventId)) },
    [dispatch]
  );
}

function useHandleDeleteEvent(): ValidEventHandler {
  const dispatch = useRaceDispatch();
  return useCallback(
    async (eventId: number) => {
      dispatch(actions.deleteEventAsync(eventId))
    },
    [dispatch]
  );
}

function useHandleSaveEvent(handleCloseEventProposalModal: () => void): SaveEventHandler {
  const dispatch = useRaceDispatch();
  const eventDetails = useSelector(selectCurrentEventDetails);
  return useCallback(
    (eventDetails: EventDetails | EventToCreate) => {
      if (isEventDetails(eventDetails)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dispatch(actions.eventEditAsync(eventDetails)).then((response: any) => {
          if (!response.error) {
            handleCloseEventProposalModal();
            dispatch(actions.eventsProposalsChangeAsync());
          }
        });
      }
    },
    [dispatch, isEventDetails, eventDetails, handleCloseEventProposalModal]
  );
}

const EventsProposalPage: FC = () => {
  const eventDetails = useSelector(selectCurrentEventDetails);
  const eventProposalModalIsVisible = useSelector(selectEventProposalModalVisible);
  const handleShowEventDetails = useHandleShowEventDetails();
  const handleCloseEventProposalModal = useHandleCloseEventProposalModal();
  const handleValidEvent = useHandleValidEvent();
  const handleDeleteEvent = useHandleDeleteEvent();
  const handleSaveEvent = useHandleSaveEvent(handleCloseEventProposalModal);
  const handleSearchEventsProposals = useSearchEventsProposals();

  useEffect(() => {
    handleSearchEventsProposals();
  }, []);
  useUserConnexionInfo();
  const [eventsProposalsTreeView, setEventsProposalsTreeView] = useState<EventsTreeView>({});
  const eventsProposals = useSelector(selectEventsProposals);
  useEffect(() => {
    console.log(eventsProposals);
    const treeView = toTreeView(eventsProposals);
    setEventsProposalsTreeView(treeView);
  }, [eventsProposals, setEventsProposalsTreeView]);

  return (
    <div>
      <div className="events-list-container">
        <EventsList
          isAdmin={true}
          events={eventsProposalsTreeView}
          noDataMessage={'Aucune demande à valider'}
          onShowEventDetails={handleShowEventDetails}
          onValidateEvent={handleValidEvent}
          onDeleteEvent={handleDeleteEvent}
        />
        <ProposalEventModal
          event={eventDetails}
          visible={eventProposalModalIsVisible}
          onClose={handleCloseEventProposalModal}
          onSaveEvent={handleSaveEvent}
        ></ProposalEventModal>
      </div>
    </div>
  );
};

export default EventsProposalPage;
