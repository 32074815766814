import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectJwtToken } from '../state/selector';
import { actions, useRaceDispatch } from '../state/store';
import { eraseCookie, getCookie, isAdminUser, setCookie } from './utils';

export function useUserConnexionInfo(): void {
  const jwtToken = useSelector(selectJwtToken);
  const dispatch = useRaceDispatch();
  useEffect(() => {
    const hasValidToken = !!jwtToken || getCookie('token') !== undefined;
    dispatch(actions.userIsConnectedStatusChange(hasValidToken));
    if (hasValidToken && getCookie('token')) {
      dispatch(actions.userAdminStatusChange(isAdminUser(getCookie('token'))));
    }
    jwtToken ? setCookie('token', jwtToken, 160) : eraseCookie('jwtToken');
  }, [jwtToken]);
}

export function useSearchEventsProposals(): () => void {
  const dispatch = useRaceDispatch();
  return useCallback(() => {
    dispatch(actions.eventsProposalsChangeAsync());
  }, [dispatch]);
}
