import React, { FC } from 'react';
import { Route } from 'react-router';
import Layout from './Layout';
import fr_FR from 'antd/lib/locale/fr_FR';

import '../style/custom.css';
import 'antd/dist/antd.min.css';
import { ConfigProvider } from 'antd';
import RaceStoreProvider from './Race/state/RaceStoreProvider';
import RaceContainer from './Race/components/Race/RaceContainer';
import EventAttendancesListContainer from './Race/components/EventAttendancesList/EventAttendancesListContainer';
import EventsProposalPage from './Race/components/EventsProposalPage/EventsProposalPage';

const App: FC = () => {
  return (
    <ConfigProvider locale={fr_FR}>
      <RaceStoreProvider>
        <Layout>
          <Route exact path="/" component={RaceContainer} />
          <Route exact path="/event/:id/attendances" component={EventAttendancesListContainer} />
          <Route exact path="/event/proposals" component={EventsProposalPage} />
        </Layout>
      </RaceStoreProvider>
    </ConfigProvider>
  );
};
App.displayName = 'App';
export default App;
