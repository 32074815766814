import moment from 'moment';
import 'moment/locale/fr';
import { EventDetails, EventsTreeView, RaceState, UserDetails } from './interface';

export const selectEvents = (state: RaceState): EventDetails[] => state.entities.events;

export const selectEventsProposals = (state: RaceState): EventDetails[] => state.entities.eventsProposals;

export const selectEventsLoading = (state: RaceState): boolean => state.ui.eventsLoading;

export const toTreeView = (events: EventDetails[]): EventsTreeView => {
  const eventYear = {} as EventsTreeView;
  if (events && events.length) {
    const eventsByYear: Record<number, EventDetails[]> = events.reduce((acc, event) => {
      const year = moment(event.date).year();
      if (acc[year]) {
        acc[year].push(event);
        return acc;
      }
      return { ...acc, [year]: [event] };
    }, {} as Record<number, EventDetails[]>);

    Object.entries(eventsByYear).forEach(([year, e]) => {
      eventYear[year as unknown as number] = e.reduce((acc, event) => {
        const month: string = moment(event.date).locale('fr').format('MMMM');
        if (acc[month]) {
          acc[month].push(event);
          return acc;
        }
        return { ...acc, [month]: [event] };
      }, {} as Record<string, EventDetails[]>);
    });
  }
  return eventYear;
};
export const selectTreeViewEvents = (state: RaceState): EventsTreeView => {
  const { events } = state.entities;
  return toTreeView(events);
};

export const selectSelectedEventId = (state: RaceState): number | undefined => state.ui.selectedEventId;

export const selectEventDrawerVisible = (state: RaceState): boolean => state.ui.eventDrawerVisible;

export const selectEventsByKey = (state: RaceState): Record<number, EventDetails> => state.ui.eventsByKey;

export const selectCurrentEventDetails = (state: RaceState): EventDetails | undefined => state.ui.currentEventDetails;

export const selectEventEditModalVisible = (state: RaceState): boolean => state.ui.eventEditModalVisible;

export const selectEventProposalModalVisible = (state: RaceState): boolean => state.ui.eventProposalModalVisible;

export const selectJwtToken = (state: RaceState): string | null => state.jwtToken || null;

export const selectUser = (state: RaceState): UserDetails | undefined => state.entities.user;
