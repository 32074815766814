import { Drawer } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import { sortBy } from 'lodash';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import React, { FC, useCallback, useMemo } from 'react';
import { Attendance, EventDetails } from '../../state/interface';
import EventAttendancesList from '../EventAttendancesList/EventAttendancesList';
import './EventDrawer.css';

interface EventDrawerProps {
  eventDetails?: EventDetails;
  onAddAttend: (eventId: number, username: string) => void;
  onClose: () => void;
  visible: boolean;
}

function useAttendances(event?: EventDetails): Attendance[] {
  return useMemo(() => {
    if (event && event.attendances) {
      return sortBy(event.attendances, 'name');
    }
    return [];
  }, [event]);
}

function useTitle(event?: EventDetails): React.ReactNode {
  return event ? (
    <>
      <Title level={5}>Details des participants</Title>
      <Text type="secondary">{event?.name}</Text>
    </>
  ) : null;
}

const EventDrawer: FC<EventDrawerProps> = ({ eventDetails, visible, onAddAttend, onClose }) => {
  const title = useMemo(() => useTitle(eventDetails), [eventDetails]);
  const sortedAttendances = useAttendances(eventDetails);
  const onAttend = useCallback(
    (values: { username: string }) => {
      if (eventDetails) onAddAttend(eventDetails?.id, values.username);
    },
    [eventDetails]
  );
  const onFinishFailed = (errorInfo: ValidateErrorEntity<{ username: string }>) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Drawer title={title} placement="right" onClose={onClose} visible={visible} size="large">
      <div className="drawer-content">
        <EventAttendancesList
          attendances={sortedAttendances}
          onAttend={onAttend}
          onFinishFailed={onFinishFailed}
        ></EventAttendancesList>
      </div>
    </Drawer>
  );
};

export default EventDrawer;
