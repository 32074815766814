import moment from 'moment';
import { AuthResponse, EventAttend, EventDetails, EventToCreate } from '../state/interface';
import { getCookie, openNotification } from './utils';

class ApiErrorType extends Error {
  message: string;
  code: number;
  constructor(message: string, code: number) {
    super();
    this.code = code;
    this.message = message;
  }
}

function ApiError(message: string, code: number): ApiErrorType {
  const error = {
    message,
    code
  };
  return error as ApiErrorType;
}
ApiError.prototype = Object.create(Error.prototype);

export async function getEvents(dateFrom: string, dateTo: string): Promise<EventDetails[]> {
  const token = getCookie('token');
  const dateToFormatted = moment(dateTo, 'DD/MM/YYYY').format('YYYY-MM-DD');
  const dateFromFormatted = moment(dateFrom, 'DD/MM/YYYY').format('YYYY-MM-DD');
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` }
  };
  const events = await fetch(
    `${process.env.REACT_APP_API_URL}/events?dateFrom=${dateFromFormatted}&dateTo=${dateToFormatted}`,
    requestOptions
  );
  return events.json() as Promise<EventDetails[]>;
}

export async function getEventsProposals(): Promise<EventDetails[]> {
  const token = getCookie('token');
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` }
  };
  const events = await fetch(`${process.env.REACT_APP_API_URL}/events/pending`, requestOptions);
  return events.json() as Promise<EventDetails[]>;
}

export async function getEventDetails(eventId: number): Promise<EventDetails> {
  const token = getCookie('token');
  const event = await fetch(`${process.env.REACT_APP_API_URL}/events/${eventId}`, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return event.json() as Promise<EventDetails>;
}

export async function attendToEvent(eventId: number, username: string): Promise<EventAttend> {
  const token = getCookie('token');
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    body: JSON.stringify({ name: username })
  };
  fetch(`${process.env.REACT_APP_API_URL}/events/${eventId}/attend`, requestOptions)
    .then((response: Response) => {
      if (response.ok) {
        return response.status;
      } else throw new ApiErrorType(response.statusText || '', response.status);
    })
    .then(() => openNotification('success', 'Vous avez bien été ajouté(e) à la liste des participants'))
    .catch((error: ApiErrorType) => {
      openNotification(
        'error',
        'Erreur',
        error.code === 409
          ? `Ce participant est déjà inscrit`
          : `Une erreur s'est produite, vous n'avez pas été ajouté(e) à la liste des participants`,
        5
      );
    });
  return { eventId, username };
}

export async function editEvent(event: EventDetails): Promise<EventDetails> {
  const token = getCookie('token');
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    body: JSON.stringify(event)
  };
  return fetch(`${process.env.REACT_APP_API_URL}/events/${event.id}`, requestOptions)
    .then((response: Response) => {
      if (response.ok) {
        return response.json() as Promise<EventDetails>;
      } else throw new Error('Something went wrong');
    })
    .then(eventDetails => {
      openNotification('success', 'Evènement modifié avec succès', '', 5);
      return {
        ...eventDetails,
        id: event.id
      };
    })
    .catch(() => {
      openNotification('error', 'Erreur', `Une erreur s'est produite, l'évènement n'a pas pu être modifié`, 5);
      throw new Error(`Une erreur s'est produite, l'évènement n'a pas pu être modifié`);
    });
}

export async function createEvent(event: EventToCreate): Promise<EventDetails> {
  const token = getCookie('token');
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    body: JSON.stringify(event)
  };
  return fetch(`${process.env.REACT_APP_API_URL}/events`, requestOptions)
    .then((response: Response) => {
      if (response.ok) {
        return response.json() as Promise<EventDetails>;
      } else throw new Error('Something went wrong');
    })
    .then(eventDetails => {
      openNotification('success', 'Evènement créé avec succès', '', 5);
      return {
        ...eventDetails
      };
    })
    .catch(() => {
      openNotification('error', 'Erreur', `Une erreur s'est produite, l'évènement n'a pas pu être créé`, 5);
      throw new Error(`Une erreur s'est produite, l'évènement n'a pas pu être créé`);
    });
}

export async function createProposalEvent(event: EventToCreate): Promise<EventDetails> {
  const token = getCookie('token');
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    body: JSON.stringify(event)
  };
  return fetch(`${process.env.REACT_APP_API_URL}/events/proposal`, requestOptions)
    .then((response: Response) => {
      if (response.ok) {
        return response.json() as Promise<EventDetails>;
      } else throw new Error('Something went wrong');
    })
    .then(eventDetails => {
      openNotification(
        'success',
        `Votre demande a bien été envoyée. \nLa course sera disponible une fois qu'un administrateur aura validé votre demande`,
        '',
        10
      );
      return {
        ...eventDetails
      };
    })
    .catch(() => {
      openNotification('error', 'Erreur', `Une erreur s'est produite, la demande n'a pas pu être envoyée`, 5);
      throw new Error(`Une erreur s'est produite, la demande n'a pas pu être envoyée`);
    });
}

export async function login(password: string): Promise<AuthResponse> {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ password })
  };
  return fetch(`${process.env.REACT_APP_API_URL}/authenticate`, requestOptions)
    .then((response: Response) => {
      if (response.ok) {
        return response.json();
      } else throw new Error('Something went wrong');
    })
    .then(token => {
      openNotification('success', 'Connexion réussie', '');
      return token;
    })
    .catch(() => openNotification('error', 'Erreur', 'Mot de passe invalide', 5));
}

export async function validateEvent(eventId: number): Promise<number> {
  const token = getCookie('token');
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    body: null
  };
  await fetch(`${process.env.REACT_APP_API_URL}/events/${eventId}/validate`, requestOptions)
    .then((response: Response) => {
      if (response.ok) {
        openNotification('success', `Evènement ajouté avec succès`)
        return response.status;
      } else throw new ApiErrorType(response.statusText || '', response.status);
    })
    .catch(() => {
      openNotification('error', 'Erreur', `Une erreur s'est produite, l'évènement n'a pas pu être ajouté`, 5);
    });

  return eventId;
}

export async function deleteEvent(eventId: number): Promise<number> {
  const token = getCookie('token');
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    body: null
  };
  await fetch(`${process.env.REACT_APP_API_URL}/events/${eventId}`, requestOptions)
    .then((response: Response) => {
      if (response.ok) {
        return response.status;
      } else throw new ApiErrorType(response.statusText || '', response.status);
    })
    .then(() => openNotification('success', `Evènement supprimé avec succès`))
    .catch(() => {
      openNotification('error', 'Erreur', `Une erreur s'est produite, l'évènement n'a pas pu être supprimé`, 5);
    });
  return eventId;
}
