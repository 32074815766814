import Modal from 'antd/lib/modal/Modal';
import Title from 'antd/lib/typography/Title';
import React, { FC, useMemo } from 'react';
import { EventDetails, EventToCreate } from '../../state/interface';
import EventEditForm from '../EventEditForm/EventEditForm';

interface EventModalProps {
  visible: boolean;
  event?: EventDetails;
  onClose: () => void;
  onSaveEvent: (event: EventDetails | EventToCreate) => void;
}

const EventModal: FC<EventModalProps> = ({ visible, event, onSaveEvent, onClose }) => {
  const actionLabel = useMemo(() => (event && event.id ? 'Modifier' : 'Créer'), [event]);
  const title = useMemo(
    () =>
      event && event.id ? (
        <Title level={5}>{`Edition de l'évènement`}</Title>
      ) : (
        <Title level={5}>{`Création d'un nouvel évènement`}</Title>
      ),
    [event]
  );

  return (
    <Modal
      title={title}
      visible={visible} // TODO remove
      onCancel={onClose} // TODO remove
      width={800}
      footer={null}
      destroyOnClose={true}
    >
      <EventEditForm event={event} actionLabel={actionLabel} onSaveEvent={onSaveEvent}></EventEditForm>
    </Modal>
  );
};

export default EventModal;
