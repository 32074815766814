import { Avatar, Button, Form, Input, List } from 'antd';
import React, { FC } from 'react';
import { Attendance } from '../../state/interface';
import Text from 'antd/lib/typography/Text';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import FormItem from 'antd/lib/form/FormItem';
import './EventAttendancesList.css';
import { ArrowLeftOutlined } from '@ant-design/icons';

interface EventAttendancesListProps {
  attendances: Attendance[];
  onAttend: (values: { username: string }) => void;
  onFinishFailed: (errorInfo: ValidateErrorEntity<{ username: string }>) => void;
  onGoBackRace?: () => void;
}

function getFirstLetters(str: string) {
  const firstLetters = str
    .split(' ')
    .map((word: string) => word[0])
    .join('');

  return firstLetters;
}

const EventAttendancesList: FC<EventAttendancesListProps> = ({
  attendances,
  onAttend,
  onFinishFailed,
  onGoBackRace
}) => {
  return (
    <div>
      <div className="header">
        <Button type="default" onClick={onGoBackRace}>
          <span className="go-back-button-content">
            <ArrowLeftOutlined /> <span className="go-back-button-label">Retour</span>
          </span>
        </Button>
      </div>
      <div className="list-container">
        <List
          itemLayout="horizontal"
          dataSource={attendances}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>{getFirstLetters(item.name)}</Avatar>
                }
                title={item.name}
              />
            </List.Item>
          )}
        />
      </div>
      <div className="footer">
        <div className="form-helper">
          <Text type="secondary">
            {`Pour faire parti des particpants, remplissez le champ suivant en respectant le format "Prénom Nom" et
          cliquer sur "Je participe !"`}
          </Text>
        </div>
        <div>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onAttend}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="form-container"
            layout="inline"
          >
            <FormItem
              label="Prénom Nom"
              name="username"
              rules={[{ required: true, message: 'Votre nom est obligatoire' }]}
            >
              <Input />
            </FormItem>

            <FormItem wrapperCol={{ offset: 8, span: 16 }} className="participate-button">
              <Button type="primary" htmlType="submit">
                Je participe !
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EventAttendancesList;
