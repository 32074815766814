import React, { FC, useCallback, useEffect, useState } from 'react';
import { Attendance } from '../../state/interface';
import { sortBy } from 'lodash';
import { actions, useRaceDispatch } from '../../state/store';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { useHistory, useParams } from 'react-router';
import EventAttendancesList from './EventAttendancesList';

interface useEventAttendancesListContainerProps {
  attendances: Attendance[];
  onAttend: (values: { username: string }) => void;
  onFinishFailed: (errorInfo: ValidateErrorEntity<{ username: string }>) => void;
  onGoRacePage: () => void;
}

function getEventIdFromUrl(): number {
  const { id } = useParams<{ id: string }>();
  return Number(id);
}

function useEventAttendancesListContainer(): useEventAttendancesListContainerProps {
  const eventId = getEventIdFromUrl();
  const dispatch = useRaceDispatch();
  const [sortedAttendances, setSortedAttendances] = useState<Attendance[]>([]);
  const history = useHistory();
  useEffect(() => {
    async function getAttendances() {
      const details = await dispatch(actions.eventDetailsChangeAsync(eventId)).unwrap();
      if (details && details.attendances) {
        const sortedAttendances = sortBy(details.attendances, 'name');
        setSortedAttendances(sortedAttendances);
      }
    }
    getAttendances();
  }, [eventId]);

  const onAttend = useCallback(
    async (values: { username: string }) => {
      if (eventId) {
        try {
          const addedAttendance = await dispatch(
            actions.attendanceAddAsync({ eventId, username: values.username })
          ).unwrap();
          const newSortedAttendances = sortBy([...sortedAttendances, { name: addedAttendance.username }], 'name');
          setSortedAttendances(newSortedAttendances);
        } catch (error) {
          console.log('Error during adding of attendance : ', error);
        }
      }
    },
    [eventId, sortedAttendances]
  );
  const onFinishFailed = (errorInfo: ValidateErrorEntity<{ username: string }>) => {
    console.log('Failed:', errorInfo);
  };

  return {
    attendances: sortedAttendances,
    onAttend,
    onFinishFailed,
    onGoRacePage: () => history.push('/')
  };
}

const EventAttendancesListContainer: FC = () => {
  const { attendances, onAttend, onFinishFailed, onGoRacePage } = useEventAttendancesListContainer();

  return (
    <EventAttendancesList
      attendances={attendances}
      onAttend={onAttend}
      onFinishFailed={onFinishFailed}
      onGoBackRace={onGoRacePage}
    ></EventAttendancesList>
  );
};

export default EventAttendancesListContainer;
