import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Space } from 'antd';
import moment, { Moment } from 'moment';
import React, { FC, useCallback, useState } from 'react';
import './RaceHeader.css';

const { RangePicker } = DatePicker;

interface RaceHeaderProps {
  isAdmin: boolean;
  defaultDateFrom: string;
  defaultDateTo: string;
  onSearch: (dateFrom: string, dateTo: string) => void;
  onShowEventEditModal: () => void;
  onShowEventProposalModal: () => void;
}

const RaceHeader: FC<RaceHeaderProps> = ({
  isAdmin,
  defaultDateFrom,
  defaultDateTo,
  onSearch,
  onShowEventEditModal,
  onShowEventProposalModal
}) => {
  const dateFormat = 'DD/MM/YYYY';
  const [dateRangeFrom, setDateRangeFrom] = useState<Moment | null>(moment(defaultDateFrom, 'MM/DD/YYYY'));
  const [dateRangeTo, setDateRangeTo] = useState<Moment | null>(moment(defaultDateTo, 'MM/DD/YYYY'));
  const onDateRangesChange = useCallback(ranges => {
    if (ranges) {
      setDateRangeFrom(ranges[0]);
      setDateRangeTo(ranges[1]);
    }
  }, []);
  const onClick = useCallback(
    dates => {
      const dateFrom = dates[0].format(dateFormat);
      const dateTo = dates[1].format(dateFormat);
      onSearch(dateFrom, dateTo);
    },
    [onSearch]
  );
  return (
    <div className="race-header">
      <div className="date-time-container">
        <Space id="verticalDateRange" direction="vertical" size={1}>
          <RangePicker format={dateFormat} defaultValue={[dateRangeFrom, dateRangeTo]} onChange={onDateRangesChange} />
        </Space>
        <Space id="horizontalDateRange" direction="horizontal" size={1}>
          <DatePicker format={dateFormat} defaultValue={dateRangeFrom || undefined} onChange={setDateRangeFrom} />
          <DatePicker format={dateFormat} defaultValue={dateRangeTo || undefined} onChange={setDateRangeTo} />
        </Space>
        <Button
          className="header-button search-button"
          type="primary"
          icon={<SearchOutlined />}
          onClick={() => onClick([dateRangeFrom, dateRangeTo])}
        >
          Rechercher
        </Button>
      </div>
      {isAdmin ? (
        <div className="event-button-container">
          <Button
            className="header-button search-button"
            type="primary"
            icon={<PlusOutlined />}
            onClick={onShowEventEditModal}
          >
            Créer un évènement
          </Button>
        </div>
      ) : (
        <div className="event-button-container">
          <Button
            className="header-button search-button"
            type="primary"
            icon={<PlusOutlined />}
            onClick={onShowEventProposalModal}
          >
            Proposer mon évènement
          </Button>
        </div>
      )}
    </div>
  );
};

export default RaceHeader;
