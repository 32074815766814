import { RaceState, RaceStateUI } from './interface';

export const DEFAULT_RACE_STATE_UI: RaceStateUI = {
  selectedEventId: undefined,
  currentEventDetails: undefined,
  eventsLoading: true,
  eventsProposalsLoading: true,
  eventsTree: {},
  eventDrawerVisible: false,
  eventEditModalVisible: false,
  eventProposalModalVisible: false,
  eventsByKey: {}
};

export const DEFAULT_RACE_STATE: RaceState = {
  entities: {
    events: [],
    eventsProposals: []
  },
  ui: DEFAULT_RACE_STATE_UI,
  jwtToken: undefined
};
