import { DatePicker, Input, Select, Button } from 'antd';
import Form, { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment-timezone';
import React, { FC, useCallback, useEffect } from 'react';
import { EventDetails, EventToCreate, isEventDetails } from '../../state/interface';
import './EventEditForm.css';

type SaveEventHandler = (event: EventDetails | EventToCreate) => void;

interface EventEditForm {
  actionLabel: string;
  event?: EventDetails;
  onSaveEvent: SaveEventHandler;
}

type EventForm = Omit<EventDetails | EventToCreate, 'date'> & {
  date: moment.Moment;
  hour: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function onFinish(values: any, event: EventToCreate | EventDetails, onSaveEvent: SaveEventHandler): void {
  const stringDate = moment(values.date, 'DD/MM/YYYY').format('YYYY-MM-DD');
  const dateTimeWithTimezone = moment.tz(`${stringDate} ${values.hour}`, 'Europe/Paris');
  let eventToSave = {
    name: values.name,
    location: values.location,
    date: dateTimeWithTimezone.format('YYYY-MM-DDTHH:mmZ'),
    distance: new String(values.distance).toUpperCase(),
    type: values.type,
    comment: values.comment,
    websiteLink: values.websiteLink,
    registrationLink: values.registrationLink,
    resultsLink: values.resultsLink
  } as EventToCreate;
  if (event && isEventDetails(event)) {
    eventToSave = {
      ...eventToSave,
      id: event.id
    } as EventDetails;
  }
  onSaveEvent(eventToSave);
}
const EventEditForm: FC<EventEditForm> = ({ event, actionLabel, onSaveEvent }) => {
  const form = useForm<EventForm>()[0];

  useEffect(() => {
    event
      ? form.setFieldsValue({
        ...event,
        date: moment(event.date, 'YYYY-MM-DD'),
        hour: moment(event.date, 'YYYY-MM-DD h:mm:ss').format('HH:mm')
      })
      : form.resetFields();
  }, [event]);

  const handleFinish = useCallback((values, event) => {
    onFinish(values, event, onSaveEvent);
  }, []);

  return (
    <Form
      form={form}
      name="event"
      autoComplete="off"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
      onFinish={values => handleFinish(values, event)}
    >
      <FormItem
        label="Nom"
        name="name"
        rules={[
          { required: true, message: `Veuillez renseigner le nom de l'évènement` },
          { max: 200, message: 'Le nom est trop long, 200 caractères maximum autorisés' }
        ]}
      >
        <Input />
      </FormItem>

      <FormItem
        label="Adresse"
        name="location"
        rules={[
          { required: true, message: `Veuillez renseigner l'adresse de l'évènement` },
          { max: 200, message: "L'adresse est trop longue, 200 caractères maximum autorisés" }
        ]}
      >
        <Input />
      </FormItem>
      <FormItem
        label="Type d'épreuve"
        name="type"
        rules={[{ required: true, message: `Veuillez choisir le type de l'évènement dans la liste` }]}
      >
        <Select>
          <Select.Option value="Triathlon">Triathlon</Select.Option>
          <Select.Option value="Duathlon">Duathlon</Select.Option>
          <Select.Option value="SwimRun">SwimRun</Select.Option>
          <Select.Option value="Running">Running</Select.Option>
          <Select.Option value="Trail">Trail</Select.Option>
          <Select.Option value="RunAndBike">Run&Bike</Select.Option>
          <Select.Option value="Other">Autre</Select.Option>
        </Select>
      </FormItem>
      <FormItem
        label="Date et heure"
        style={{ marginBottom: 0 }}
        rules={[{ required: true, message: `Veuillez renseiger la date de l'évènement` }]}
      >
        <FormItem
          name="date"
          rules={[{ type: 'object', required: true, message: `Veuillez renseiger la date de l'évènement` }]}
          style={{ display: 'inline-block' }}
        >
          <DatePicker placeholder="Date" format={'DD/MM/YYYY'} />
        </FormItem>
        <FormItem
          name="hour"
          rules={[{ required: true, message: `Veuillez renseiger l'heure de l'évènement` }]}
          style={{ display: 'inline-block', margin: '0 8px' }}
        >
          <Input type="time" placeholder="Heure"></Input>
        </FormItem>
      </FormItem>

      <FormItem
        label="Distance"
        name="distance"
        rules={[
          { required: true, message: `Veuillez renseiger la distance de l'épreuve (ex: S, M, L, 10km etc...)` },
          { max: 200, message: '100 caractères maximum autorisés' }
        ]}
      >
        <Input />
      </FormItem>
      <FormItem
        label="Description"
        name="comment"
        rules={[{ max: 500, message: 'La description est trop longue, 500 caractères maximum autorisés' }]}
      >
        <TextArea />
      </FormItem>

      <FormItem label="Site web" name="websiteLink" rules={[{ max: 500, message: '500 caractères maximum autorisés' }]}>
        <Input />
      </FormItem>

      <FormItem
        label="Lien d'inscription"
        name="registrationLink"
        rules={[{ max: 500, message: '500 caractères maximum autorisés' }]}
      >
        <Input />
      </FormItem>

      <FormItem
        label="Lien des résultats"
        name="resultsLink"
        rules={[{ max: 500, message: '500 caractères maximum autorisés' }]}
      >
        <Input />
      </FormItem>
      <FormItem className="form-item-submit-container" wrapperCol={{ span: 20 }}>
        <Button type="primary" htmlType="submit" size="large" className="submit-button">
          {actionLabel}
        </Button>
      </FormItem>
    </Form>
  );
};

export default EventEditForm;
