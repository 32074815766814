import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Collapse, Space, Tag, Tooltip } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import Link from 'antd/lib/typography/Link';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import moment from 'moment-timezone';

import 'moment/locale/fr';
import React, { FC, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { EventDetails } from '../../state/interface';
import './EventsCollapse.css';

interface EventsCollapseProps {
  isAdmin: boolean;
  events: EventDetails[];
  onShowAttendancesDrawer?: (eventId: number) => void;
  onShowDetails: (eventId: number) => void;
  onValidateEvent: (eventId: number) => void;
  onDeleteEvent: (eventId: number) => void;
}

function getExtra(event: EventDetails): React.ReactNode {
  return useMemo(
    () => (
      <div id="tagExtra">
        <Tag className="event-header-tag" color="magenta">{`Date : ${moment(event.date).format('LLLL')}`}</Tag>
        <Tag className="event-header-tag" color="volcano">{`Nb de participants : ${event.attendeesCount}`}</Tag>
      </div>
    ),
    [event]
  );
}

function useHeader(event: EventDetails): React.ReactNode {
  const name = useMemo(() => `${event.name} ${event.status === 'Pending' ? '- A VALIDER' : ''} `, [event]);
  return useMemo(
    () => (
      <>
        <div id="headerWeb">
          <Title level={4} style={{ color: '#ff5e00' }}>
            {name}
          </Title>
        </div>
        <div id="headerMobile">
          <Title level={4} style={{ color: '#ff5e00' }}>
            {event.name}
          </Title>
          <div id="tagHeader">
            <Tag className="event-header-tag" color="magenta">{`Date : ${moment(event.date).format('LLLL')}`}</Tag>
            <Tag className="event-header-tag" color="volcano">{`Nb de participants : ${event.attendeesCount}`}</Tag>
          </div>
        </div>
      </>
    ),
    [event]
  );
}
const EventsCollapse: FC<EventsCollapseProps> = ({
  isAdmin,
  events,
  onShowAttendancesDrawer,
  onShowDetails,
  onValidateEvent,
  onDeleteEvent
}) => {
  const defaultActiveKeys = useMemo(() => events.map(e => e.id), [events]);
  const history = useHistory();
  const onShowAttendancesPage = (eventId: number) => {
    history.push(`/event/${eventId}/attendances`);
  };
  const isPendingEvent = useCallback((event: EventDetails) => event.status === 'Pending', []);
  return (
    <Space className="events-collapse-container" key="CardSpace" direction="horizontal">
      <Collapse collapsible="header" defaultActiveKey={defaultActiveKeys}>
        {events.map(event => (
          <CollapsePanel
            className={event.status === 'Pending' ? 'pending-event' : ''}
            header={useHeader(event)}
            key={event.id}
            extra={getExtra(event)}
          >
            <div className="collapse-panel">
              <div className="collapse-panel-details-content">
                <div className="main-content">
                  <Text strong>Description</Text>
                  <br />
                  <Text>{`${event.type} - ${event.distance}`}</Text>
                  <br />
                  <Text>{event.comment}</Text>
                </div>
                <div>
                  <Text strong>Localisation : </Text>
                  <Text>{event.location}</Text>
                </div>
                <div>
                  <Text strong>Distance : </Text>
                  <Text>{event.distance}</Text>
                </div>
                <div>
                  <Text strong>Site web de l&apos;évènement : </Text>
                  {event.websiteLink ? (
                    <Link href={event.websiteLink} target="_blank">
                      {event.websiteLink}
                    </Link>
                  ) : (
                    <Text type="secondary">Non renseigné</Text>
                  )}
                </div>
                <div>
                  <Text strong>Lien d&apos;inscription : </Text>
                  {event.registrationLink ? (
                    <Link href={event.registrationLink} target="_blank">
                      {event.registrationLink}
                    </Link>
                  ) : (
                    <Text type="secondary">Non renseigné</Text>
                  )}
                </div>
                <div>
                  <Text strong>Resultats : </Text>
                  {event.resultsLink ? (
                    <Link href={event.resultsLink} target="_blank">
                      {event.resultsLink}
                    </Link>
                  ) : (
                    <Text type="secondary">Non renseigné</Text>
                  )}
                </div>
              </div>
              <div className="collapse-panel-buttons-content">
                <div className="buttons-panel-button-container">
                  {isAdmin ? (
                    <div>
                      <Tooltip title="Modifier l'évènement">
                        <Button
                          id="editEventButtonWeb"
                          className="edit-event-container"
                          type="default"
                          shape="circle"
                          icon={<EditOutlined />}
                          onClick={() => onShowDetails(event.id)}
                        />
                      </Tooltip>
                    </div>
                  ) : null}
                  {!isPendingEvent(event) && onShowAttendancesDrawer ? (
                    isAdmin ? (
                      <div className='admin-button-container'>
                        <Button id="showAttendancesDrawer" type="primary" onClick={() => onShowAttendancesDrawer(event.id)}>
                          Voir la liste des participants
                        </Button>
                        <Button id="deleteEventButton" danger type="primary" icon={<DeleteOutlined />} onClick={() => onDeleteEvent(event.id)}>
                        </Button>
                      </div>
                    ) : (
                      <Button id="showAttendancesDrawer" type="primary" onClick={() => onShowAttendancesDrawer(event.id)}>
                        Voir la liste des participants
                      </Button>
                    )
                  ) : (
                    <div className='admin-button-container'>
                      <Button id="acceptEventButton" type="primary" onClick={() => onValidateEvent(event.id)}>
                        Valider l&apos;évènement
                      </Button>
                      <Button id="deleteEventButton" danger type="primary" icon={<DeleteOutlined />} onClick={() => onDeleteEvent(event.id)}>
                      </Button>
                    </div >
                  )}
                  <div id="footerMobile">
                    <div>
                      <Button type="primary" onClick={() => onShowAttendancesPage(event.id)}>
                        Voir la liste des participants
                      </Button>
                    </div>
                    <div>
                      <Button
                        className="edit-event-container"
                        type="default"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => onShowDetails(event.id)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CollapsePanel>
        ))}
      </Collapse>
    </Space>
  );
};

export default EventsCollapse;
