import { PayloadAction } from '@reduxjs/toolkit';
import { FulfilledAction } from '../../../common/react/redux';
import { AuthResponse, EventAttend, EventDetails, RaceState } from './interface';
import { isAdminUser } from '../common/utils';

export function onEventsChange(state: RaceState, { payload: events }: PayloadAction<EventDetails[]>): RaceState {
  state.entities.events = events;
  return state;
}

export function onEventsChangeAsyncFullFilled(
  state: RaceState,
  { payload }: FulfilledAction<EventDetails[]>
): RaceState {
  Object.assign(state.entities, { events: payload });
  Object.assign(state.ui, {
    ...state.ui,
    eventsLoading: false
  });
  return state;
}

export function onEventsChangeAsyncPending(state: RaceState): RaceState {
  Object.assign(state.ui, {
    ...state.ui,
    eventsLoading: true
  });
  return state;
}

export function onEventsChangeAsyncRejected(state: RaceState): RaceState {
  Object.assign(state.ui, {
    ...state.ui,
    eventsLoading: false
  });
  return state;
}

export function onEventsProposalsChangeAsyncFullFilled(
  state: RaceState,
  { payload }: FulfilledAction<EventDetails[]>
): RaceState {
  Object.assign(state.entities, { eventsProposals: payload });
  Object.assign(state.ui, {
    ...state.ui,
    eventsProposalsLoading: false
  });
  return state;
}

export function onEventsProposalsChangeAsyncPending(state: RaceState): RaceState {
  Object.assign(state.ui, {
    ...state.ui,
    eventsProposalsLoading: true
  });
  return state;
}

export function onEventsProposalsChangeAsyncRejected(state: RaceState): RaceState {
  Object.assign(state.ui, {
    ...state.ui,
    eventsProposalsLoading: false
  });
  return state;
}

export function onCurrentEventDetailsChangeAsyncRejected(state: RaceState): RaceState {
  return state;
}

export function onCurrentEventDetailsChangeAsyncFullFilled(
  state: RaceState,
  { payload }: FulfilledAction<EventDetails>
): RaceState {
  return {
    ...state,
    ui: {
      ...state.ui,
      currentEventDetails: payload
    }
  };
}

export function onCurrentEventDetailsChangeAsyncPending(state: RaceState): RaceState {
  // TODO
  return state;
}

export function OnSelectedEventChange(state: RaceState, { payload: eventId }: PayloadAction<number>): RaceState {
  return {
    ...state,
    ui: {
      ...state.ui,
      selectedEventId: eventId
    }
  };
}

export function OnResetCurrentEventDetails(state: RaceState): RaceState {
  return {
    ...state,
    ui: {
      ...state.ui,
      currentEventDetails: undefined
    }
  };
}

export function OnResetSelectedEvent(state: RaceState): RaceState {
  return {
    ...state,
    ui: {
      ...state.ui,
      selectedEventId: undefined
    }
  };
}

export function OnDrawerEventVisibilityChage(
  state: RaceState,
  { payload: visible }: PayloadAction<boolean>
): RaceState {
  Object.assign(state.ui, { eventDrawerVisible: visible });
  return state;
}

export function OnModalEditEventVisibilityChange(
  state: RaceState,
  { payload: visible }: PayloadAction<boolean>
): RaceState {
  Object.assign(state.ui, { eventEditModalVisible: visible });
  return state;
}

export function OnModalProposalEventVisibilityChange(
  state: RaceState,
  { payload: visible }: PayloadAction<boolean>
): RaceState {
  Object.assign(state.ui, { eventProposalModalVisible: visible });
  return state;
}

export function onAttendanceAddAsyncPending(state: RaceState): RaceState {
  return state;
}

export function onAttendanceAddAsyncFullFilled(state: RaceState, { payload }: FulfilledAction<EventAttend>): RaceState {
  const { eventId, username } = payload;
  const updatedEvents = state.entities.events;
  updatedEvents.map(e => {
    if (e.id === eventId) {
      e.attendeesCount = e.attendeesCount ? e.attendeesCount + 1 : 1;
    }
  });
  Object.assign(state.entities.events, updatedEvents);
  if (state.ui.currentEventDetails) {
    const { attendances } = state.ui.currentEventDetails;
    Object.assign(state.ui.currentEventDetails, {
      ...state.ui.currentEventDetails,
      attendances: [...(attendances || []), { name: username }]
    });
  }
  return state;
}

export function onAttendanceAddAsyncRejected(state: RaceState): RaceState {
  return state;
}

export function onEventAddAsyncPending(state: RaceState): RaceState {
  return state;
}

export function onEventAddAsyncFullFilled(state: RaceState): RaceState {
  return {
    ...state,
    ui: {
      ...state.ui,
      currentEventDetails: undefined,
      eventEditModalVisible: false
    }
  };
}

export function onEventAddAsyncRejected(state: RaceState): RaceState {
  return state;
}

export function onEventEditAsyncPending(state: RaceState): RaceState {
  return state;
}

export function onEventEditAsyncFullFilled(state: RaceState): RaceState {
  return {
    ...state,
    ui: {
      ...state.ui,
      currentEventDetails: undefined,
      eventEditModalVisible: false
    }
  };
}

export function onEventEditAsyncRejected(state: RaceState): RaceState {
  return state;
}

export function onLoginAsyncPending(state: RaceState): RaceState {
  return state;
}

export function onLoginAsyncFullFilled(state: RaceState, { payload }: FulfilledAction<AuthResponse>): RaceState {
  state.jwtToken = payload.jwtToken;
  state.entities.user = {
    admin: isAdminUser(payload.jwtToken),
    isConnected: true
  };
  return state;
}

export function onLoginAsyncRejected(state: RaceState): RaceState {
  state.entities.user = {
    admin: false,
    isConnected: false
  };
  return state;
}

export function onEventAddProposalAsyncPending(state: RaceState): RaceState {
  return state;
}

export function onEventAddProposalFullFilled(state: RaceState): RaceState {
  return {
    ...state,
    ui: {
      ...state.ui,
      currentEventDetails: undefined,
      eventProposalModalVisible: false
    }
  };
}

export function onEventAddProposalRejected(state: RaceState): RaceState {
  return state;
}

export function onValidateEventAsyncPending(state: RaceState): RaceState {
  return state;
}

export function onValidateEventFullFilled(state: RaceState, { payload }: FulfilledAction<number>): RaceState {
  return {
    ...state,
    entities: {
      ...state.entities,
      eventsProposals: state.entities.eventsProposals.filter(e => e.id !== payload)
    }
  };
}

export function onValidateEventRejected(state: RaceState): RaceState {
  return state;
}

export function onDeleteEventAsyncPending(state: RaceState): RaceState {
  return state;
}

export function onDeleteEventFullFilled(state: RaceState, { payload }: FulfilledAction<number>): RaceState {
  //Only Update entities.eventsProposals with deleted because entities.events update cause too muche rendering
  return {
    ...state,
    entities: {
      ...state.entities,
      eventsProposals: state.entities.eventsProposals.filter(e => e.id !== payload)
    }
  };
}

export function onDeleteEventRejected(state: RaceState): RaceState {
  return state;
}

export function OnUserAdminStatusChange(state: RaceState, { payload: isAdmin }: PayloadAction<boolean>): RaceState {
  state.entities.user = {
    ...state.entities.user,
    admin: isAdmin,
    isConnected: state.entities.user ? true : false
  };
  return state;
}

export function OnUserIsConnectedStatusChange(
  state: RaceState,
  { payload: isConnected }: PayloadAction<boolean>
): RaceState {
  state.entities.user = {
    ...state.entities.user,
    isConnected
  };
  return state;
}
