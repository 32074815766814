import Modal from 'antd/lib/modal/Modal';
import Title from 'antd/lib/typography/Title';
import React, { FC } from 'react';
import { EventDetails, EventToCreate } from '../../state/interface';
import EventEditForm from '../EventEditForm/EventEditForm';

interface ProposalEventModalProps {
  visible: boolean;
  event?: EventDetails;
  onClose: () => void;
  onSaveEvent: (event: EventDetails | EventToCreate) => void;
}

const ProposalEventModal: FC<ProposalEventModalProps> = ({ visible, event, onSaveEvent, onClose }) => {
  const title = <Title level={5}>{`Demande d'ajout d'évènement`}</Title>;
  return (
    <Modal
      title={title}
      visible={visible} // TODO remove
      onCancel={onClose} // TODO remove
      width={800}
      footer={null}
      destroyOnClose={true}
    >
      <EventEditForm event={event} actionLabel={'Valider'} onSaveEvent={onSaveEvent}></EventEditForm>
    </Modal>
  );
};

export default ProposalEventModal;
